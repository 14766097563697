import {
  Badge,
  Box,
  BoxProps,
  Divider,
  Flex,
  IconButton,
  SlideFade,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { TrackedSection } from '$/components/common/TrackedSection';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { BrandLink } from '$/components/core/BrandLink';
import { NavbarButton } from '$/components/layouts/BaseDashboardLayout/components/MobileHeader/components/NavbarButton';
import { NavbarLabel } from '$/components/layouts/BaseDashboardLayout/components/MobileHeader/components/NavbarLabel';
import { NavbarLink } from '$/components/layouts/BaseDashboardLayout/components/NavbarLink';
import { NavbarLinks } from '$/components/layouts/BaseDashboardLayout/components/NavbarLinks';
import { useFeedbackModal } from '$/hooks/useFeedbackModal';
import { signOut } from '$/services/usecases/authentication';
import { useProfileStore } from '$/stores/useProfileStore';

export const MobileHeader: FC<BoxProps> = (props) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [renderMenuContent, setRenderMenuContent] = useState(false);
  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();
  const onOpenFeedbackModal = useFeedbackModal.useOnOpen();
  const onOpenProfileModal = useProfileStore.useOnModalOpen();

  return (
    <Flex pos='relative' flex='1'>
      <Flex
        as='header'
        layerStyle='DashboardSection'
        align='center'
        justify='space-between'
        w='full'
        h='56px'
        px='18px'
        {...props}
      >
        <BrandLink />
        <Spacer />
        <Badge
          h='5'
          mr='2'
          px='1'
          py='0.5'
          color='primaryButton.color'
          lineHeight='4'
          textTransform='none'
          bg='primaryButton.background'
        >
          Beta
        </Badge>
        <IconButton
          aria-label={t('dashboard.header.openMenu')}
          icon={
            isMenuOpen ? <Icon icon='close' w='14px' /> : <Icon icon='burger' />
          }
          onClick={() => setIsMenuOpen((state) => !state)}
          variant='ghost'
        />
      </Flex>

      <Box
        pos='absolute'
        zIndex='popover'
        bottom='-8px'
        w='full'
        transform='translateY(100%)'
      >
        <SlideFade
          in={isMenuOpen}
          offsetY='-8px'
          onAnimationComplete={() => {
            if (!isMenuOpen) setRenderMenuContent(false);
          }}
          onAnimationStart={() => {
            if (isMenuOpen) setRenderMenuContent(true);
          }}
        >
          {renderMenuContent && (
            <Stack layerStyle='DashboardSection' gap='0' overflow='hidden'>
              <NavbarLinks onClick={() => setIsMenuOpen(false)} />
              <Divider mt='8px' mb='24px' />
              <NavbarLabel label={t('dashboard.header.manageAccount')} />

              {hasPermission('General_User_Profile') && (
                <TrackedSection
                  contentInteraction='ClickButton'
                  contentName='CloudAccount'
                  contentPiece='Account'
                  contentTarget='Manage'
                >
                  <NavbarButton
                    icon={<Icon icon='account' />}
                    title={t('dashboard.authentication.profile.yourProfile')}
                    onClick={() => {
                      setIsMenuOpen(false);
                      onOpenProfileModal();
                    }}
                  />
                </TrackedSection>
              )}

              {hasPermission('General_Abo_Page') && (
                <NavbarLink
                  icon={<Icon icon='leaderboard' />}
                  title={t('dashboard.header.subscriptions')}
                  to='/pricing'
                  onClick={() => setIsMenuOpen(false)}
                />
              )}

              {hasPermission('General_User_Profile') && (
                <TrackedSection
                  contentInteraction='ClickButton'
                  contentName='CloudAccount'
                  contentPiece='Account'
                  contentTarget='Logout'
                >
                  <NavbarButton
                    icon={<Icon icon='arrow_right' width='13px' />}
                    title={t('dashboard.header.signOut')}
                    onClick={async () => {
                      await signOut();
                      setIsMenuOpen(false);
                    }}
                  />
                </TrackedSection>
              )}

              {hasPermission('General_Login_Register') && (
                <NavbarButton
                  onClick={() => {
                    setIsMenuOpen(false);
                    onOpenAuthenticationModal('login');
                  }}
                  icon={<Icon icon='arrow_right' width='13px' />}
                  title={t('dashboard.header.signIn')}
                />
              )}

              <Divider mt='8px' mb='20px' />
              <Flex align='center' justify='space-between' w='full' px='4'>
                <Text fontSize='md' fontWeight='bold'>
                  {t('dashboard.header.theme')}
                </Text>
                <Box h='32px'>
                  <ThemeSwitcher />
                </Box>
              </Flex>
              <Divider mt='20px' mb='24px' />
              <NavbarLabel label={t('general.additional')} />
              <NavbarButton
                onClick={() => {
                  setIsMenuOpen(false);
                  onOpenFeedbackModal();
                }}
                icon={<Icon icon='message' width='13px' />}
                title={t('feedback.mobileFeedbackTitle')}
              />
              <Divider mt='8px' mb='20px' />
              <NavbarLabel label={t('dashboard.header.legal')} />
              <NavbarLink
                icon={<Icon icon='upload_file' />}
                title={t('dashboard.legal.imprint')}
                to='/imprint'
                onClick={() => setIsMenuOpen(false)}
              />
              <NavbarLink
                icon={<Icon icon='upload_file' />}
                title={t('dashboard.legal.integrity')}
                to='/integrity'
                onClick={() => setIsMenuOpen(false)}
              />
              <NavbarLink
                icon={<Icon icon='upload_file' />}
                title={t('dashboard.legal.privacy')}
                to='/privacy'
                onClick={() => setIsMenuOpen(false)}
              />
              <NavbarLink
                icon={<Icon icon='upload_file' />}
                title={t('dashboard.legal.terms')}
                to='/tos'
                onClick={() => setIsMenuOpen(false)}
              />
            </Stack>
          )}
        </SlideFade>
      </Box>
    </Flex>
  );
};
