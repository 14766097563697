import { DataLayer } from '@piwikpro/react-piwik-pro';

import {
  AuthModalType,
  useAuthenticationStore,
} from '$/components/core/Authentication/stores/useAuthenticationStore';
import { t } from '$/components/core/Localization/18n';
import { TypedToOptions } from '$/components/core/StyledLink';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { useGeneralStore } from '$/stores/useGeneralStore';
import { PiwikDesignOption, usePiwikStore } from '$/stores/usePiwikStore';
import { ProfileModals } from '$/stores/useProfileStore';
import { capitalizeSentence } from '$/utils/stringUtils';

export type PiwikEventCategory =
  | 'FilterCollections'
  | 'FilterInspiration'
  | 'ColorTone'
  | 'WallCovering'
  | 'FloorCovering'
  | 'FacadeCovering'
  | 'Fullscreen'
  | 'SaveProject'
  | 'ShareProjekt'
  | 'ShareProjektInvite'
  | 'ShareProjektLink'
  | 'ExportProject'
  | 'ExportProjectButton';

export type PiwikEventAction =
  | 'Producer'
  | 'Collection'
  | 'Colorgroup'
  | 'LightReferenceValueMin'
  | 'LightReferenceValueMax'
  | 'Productgroup'
  | 'Floor_Colorintensity'
  | 'Floor_Design'
  | 'Floor_Material'
  | 'Floor_Use'
  | 'Wallpaper_Colorintensity'
  | 'Wallpaper_Design'
  | 'Click'
  | 'AddFavorite'
  | 'Roomtype';

export type PiwikContentInteraction =
  | 'ClickImage'
  | 'ClickPreview'
  | 'ClickButton'
  | 'ClickVariants'
  | 'ClickPerspective';

export type PiwikContentName =
  | 'CollectionOverlay'
  | 'CloudAccount'
  | 'Favorite'
  | 'Variants'
  | 'DisplayPerspectives'
  | 'MainMenu';

export type RoomSource = { roomSourceIndex: number };

export type UriTarget = { uri: string };

export type NamedTarget = { targetName: string };

export type CounterTarget = { targetCount: number };

export type TrackedProfileInputField = 'EditName' | 'EditMail';

export type LanguageOption = 'German' | 'English';

export type PiwikContentPiece =
  | 'Preview'
  | 'ImageRow'
  | 'Buttons'
  | 'Registration'
  | 'Login'
  | 'RegisterVerify'
  | 'ForgetPassword'
  | 'Account'
  | 'ManageAccount'
  | 'EditPassword'
  | 'DeleteAccount'
  | 'ManageFavorite'
  | 'EditFolder'
  | 'NewFolder'
  | 'NewVariante'
  | 'DeleteVariante'
  | 'CompareVariantes'
  | 'Perspectives'
  | 'BrandMenu'
  | 'Languages'
  | 'Themes'
  | 'Imprint'
  | 'Integrity'
  | 'Privacy'
  | 'Terms'
  | 'ChooseLanguage'
  | 'OpenIntegrityDocument'
  | TrackedProfileInputField
  | RoomSource;

export type PiwikContentTarget =
  | 'DesignMaterial'
  | 'Inspiration'
  | 'Close'
  | 'Open'
  | 'NewAccount'
  | 'Login'
  | 'ForgetPassword'
  | 'Reset'
  | 'Back'
  | 'Manage'
  | 'Logout'
  | 'Picture'
  | 'Cancel'
  | 'Submit'
  | 'EditPassword'
  | 'DeleteAccount'
  | 'Edit'
  | 'Delete'
  | 'EditFolder'
  | 'NewFolder'
  | 'Enable'
  | 'Disable'
  | 'InsertName'
  | 'Show'
  | 'Choose'
  | 'Light'
  | 'Dark'
  | TrackedProfileInputField
  | RoomSource
  | UriTarget
  | NamedTarget
  | CounterTarget
  | LanguageOption;

export const isRoomSource = (
  input: PiwikContentPiece | PiwikContentTarget,
): input is RoomSource => {
  return (input as RoomSource).roomSourceIndex !== undefined;
};

export const isUriTarget = (
  input: PiwikContentPiece | PiwikContentTarget,
): input is UriTarget => {
  return (input as UriTarget).uri !== undefined;
};

export const isNamedTarget = (
  input: PiwikContentTarget,
): input is NamedTarget => {
  return (input as NamedTarget).targetName !== undefined;
};

export const isCounterTarget = (
  input: PiwikContentTarget,
): input is CounterTarget => {
  return (input as CounterTarget).targetCount !== undefined;
};

export enum DataLayerVariableOptions {
  Version = 'version',
  Projects = 'projects',
  Favorites = 'favorites',
  DesignOrigin = 'designOrigin',
  Design = 'design',
  DesignName = 'designName',
  InspirationImage = 'inspirationImage',
  EditorProject = 'editorProject',
  Language = 'language',
}

export const mapMaterialTypeToCategory = (
  type: MaterialType,
): PiwikDesignOption | PiwikEventCategory => {
  switch (type) {
    case 'uni':
      return 'ColorTone';
    case 'floor':
      return 'FloorCovering';
    case 'wall':
      return 'WallCovering';
    case 'facade':
      return 'FacadeCovering';
  }
};

export const mapFilterGroupTypeToAction = (
  group: string,
  activeFilterType?: FilterType,
): PiwikEventAction | null => {
  switch (group) {
    case 'producer':
      return 'Producer';
    case 'collection':
      return 'Collection';
    case 'color':
      return 'Colorgroup';
    case 'SEARCH_CRITERIA_20':
      return 'Productgroup';
    case 'SEARCH_CRITERIA_39':
      return activeFilterType === 'floor'
        ? 'Floor_Colorintensity'
        : 'Wallpaper_Colorintensity';
    case 'SEARCH_CRITERIA_36':
      return activeFilterType === 'floor' ? 'Floor_Design' : 'Wallpaper_Design';
    case 'SEARCH_CRITERIA_37':
      return 'Floor_Material';
    case 'SEARCH_CRITERIA_38':
      return 'Floor_Use';
    default:
      return null;
  }
};

export const mapPathnameToPage = (
  pathname: TypedToOptions,
): { pageTitle: string; dimensions: DataLayerVariableOptions[] } => {
  const defaultDimensions = [
    DataLayerVariableOptions.Version,
    DataLayerVariableOptions.Language,
  ];

  switch (true) {
    case pathname === '/':
      return {
        pageTitle: 'Welcome Page',
        dimensions: [
          ...defaultDimensions,
          DataLayerVariableOptions.Projects,
          DataLayerVariableOptions.Favorites,
        ],
      };
    case pathname === '/projects':
      return {
        pageTitle: 'Projects',
        dimensions: defaultDimensions,
      };
    case pathname === '/inspirations':
      return {
        pageTitle: 'Inspiration',
        dimensions: [
          ...defaultDimensions,
          DataLayerVariableOptions.DesignOrigin,
          DataLayerVariableOptions.Design,
          DataLayerVariableOptions.DesignName,
        ],
      };
    case pathname === '/collections':
      return {
        pageTitle: 'Collections',
        dimensions: defaultDimensions,
      };
    case pathname === '/collections/colors':
      return {
        pageTitle: 'Collections-Colortone',
        dimensions: defaultDimensions,
      };
    case pathname === '/collections/walls':
      return {
        pageTitle: 'Collections-Wallcovering',
        dimensions: defaultDimensions,
      };
    case pathname === '/collections/floors':
      return {
        pageTitle: 'Collections-Floorcovering',
        dimensions: defaultDimensions,
      };
    case pathname === '/favorites':
      return {
        pageTitle: 'Favorites',
        dimensions: defaultDimensions,
      };
    case pathname?.includes('/inspirations/'):
      return {
        pageTitle: 'Inspiration-Editor',
        dimensions: [
          ...defaultDimensions,
          DataLayerVariableOptions.DesignOrigin,
          DataLayerVariableOptions.Design,
          DataLayerVariableOptions.DesignName,
          DataLayerVariableOptions.InspirationImage,
        ],
      };
    case pathname?.includes('/editor'):
      return {
        pageTitle: 'Projects-Editor',
        dimensions: [
          ...defaultDimensions,
          DataLayerVariableOptions.EditorProject,
        ],
      };
    case pathname === '/pricing':
      return {
        pageTitle: 'Subscription Page',
        dimensions: defaultDimensions,
      };
    case pathname === '/imprint':
      return {
        pageTitle: 'Imprint',
        dimensions: defaultDimensions,
      };
    case pathname === '/integrity':
      return {
        pageTitle: 'Integrity',
        dimensions: defaultDimensions,
      };
    case pathname === '/privacy':
      return {
        pageTitle: 'Privacy',
        dimensions: defaultDimensions,
      };
    case pathname === '/tos':
      return {
        pageTitle: 'Terms',
        dimensions: defaultDimensions,
      };
    default:
      return {
        dimensions: [],
        pageTitle: capitalizeSentence(pathname?.replace('/', ' ') ?? ''),
      };
  }
};

export const mapAuthModalTypeToPiwikContentPiece = (
  authModalType: AuthModalType,
): PiwikContentPiece | undefined => {
  switch (authModalType) {
    case 'login':
      return 'Login';
    case 'register':
      return 'Registration';
    case 'passwordForgotten':
      return 'ForgetPassword';
    case 'registerOtp':
      return 'RegisterVerify';
    default:
      console.error('could not map modal type to piwik content piece');
      return undefined;
  }
};

export const mapProfileModalToPiwikContentPiece = (
  profileModal: ProfileModals,
): PiwikContentPiece | undefined => {
  switch (profileModal) {
    case 'profile':
      return 'ManageAccount';
    case 'change_password':
      return 'EditPassword';
    case 'delete_account':
      return 'DeleteAccount';
  }
};

export const setDataLayerValues = (
  dimensions: DataLayerVariableOptions[],
  pathname: string,
  projectCount: number | null = null,
) => {
  const newDataLayerVariables = dimensions.reduce<Record<string, string>>(
    (acc, dimension) => {
      switch (dimension) {
        case DataLayerVariableOptions.Version: {
          const { userRole } = useAuthenticationStore.getState();
          acc[dimension] = userRole;
          break;
        }
        case DataLayerVariableOptions.Favorites: {
          const { userRole } = useAuthenticationStore.getState();

          if (userRole !== 'free') {
            const { favorites } = useFavoriteStore.getState();
            acc[dimension] = favorites.length.toString();
          }
          break;
        }
        case DataLayerVariableOptions.InspirationImage: {
          const inspirationId = pathname.replace('/inspirations/', '');
          acc[dimension] = inspirationId;
          break;
        }
        case DataLayerVariableOptions.DesignOrigin: {
          const { designOrigin } = usePiwikStore.getState();
          if (designOrigin != null) {
            acc[dimension] = designOrigin;
          }
          break;
        }
        case DataLayerVariableOptions.Design: {
          const { design } = usePiwikStore.getState();
          if (design != null) {
            acc[dimension] = design;
          }
          break;
        }
        case DataLayerVariableOptions.DesignName: {
          const { designName } = usePiwikStore.getState();
          if (designName != null) {
            acc[dimension] = designName;
          }
          break;
        }
        case DataLayerVariableOptions.Projects: {
          if (projectCount != null) {
            acc[dimension] = projectCount.toString();
          }
          break;
        }
        case DataLayerVariableOptions.EditorProject: {
          const editorProjectMode = window.location.search.includes(
            'projectId=',
          )
            ? 'Existing'
            : 'New';
          acc[dimension] = editorProjectMode;
          break;
        }
        case DataLayerVariableOptions.Language: {
          const language = useGeneralStore.getState().selectedLanguage;
          acc[dimension] = t(`languages.${language}`, { lng: 'en' });
          break;
        }
      }
      return acc;
    },
    {},
  );

  DataLayer.push({ event: 'page_info', page_info: newDataLayerVariables });
};

export const parseContentPiece = (input: PiwikContentPiece) => {
  if (isRoomSource(input)) {
    return 'RoomSource' + input.roomSourceIndex.toString();
  } else {
    return input;
  }
};

export const parseContentTarget = (input: PiwikContentTarget) => {
  if (isUriTarget(input)) {
    return input.uri;
  } else if (isRoomSource(input)) {
    return 'RoomSource' + input.roomSourceIndex.toString();
  } else if (isNamedTarget(input)) {
    return input.targetName;
  } else if (isCounterTarget(input)) {
    return input.targetCount.toString();
  } else {
    return input;
  }
};
