import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { changeLanguage } from 'i18next';
import { z } from 'zod';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { setPageViewInformation } from '$/logger';
// import { LoginPage } from '$/pages/Authentication/pages/LoginPage';
import { NotFoundPage } from '$/pages/NotFoundPage';
import { validateToken } from '$/services/usecases/authentication';
import { projectsQuery } from '$/services/usecases/projects/queries';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { usePiwikStore } from '$/stores/usePiwikStore';
import { useProjectStore } from '$/stores/useProjectStore';

interface RouterContext {
  client: QueryClient;
}

const globalSearchSchema = z.object({
  keyMode: z.boolean().optional(),
});

export const RootRoute = createRootRouteWithContext<RouterContext>()({
  notFoundComponent: NotFoundPage,
  beforeLoad: async ({ location, context: { client }, search }) => {
    await validateToken();

    const { user } = useAuthenticationStore.getState();
    let projectCount: number | null = null;

    if (user?.id != null) {
      const data = await client.ensureQueryData(projectsQuery());
      projectCount = data?.response.payload.length ?? 0;
      useProjectStore.setState({ projects: data?.response.payload ?? [] });

      await useFavoriteStore.getState().getFavorites();
    }

    setPageViewInformation(location.pathname, projectCount);

    usePiwikStore.setState({
      design: null,
      designName: null,
      designOrigin: null,
    });

    if (search.keyMode != null) {
      localStorage.setItem('translationKeyMode', search.keyMode.toString());
    }

    const isTranslationKeyMode =
      search.keyMode ?? localStorage.getItem('translationKeyMode') === 'true';

    if (isTranslationKeyMode) {
      await changeLanguage('cimode');
    }
  },
  component: () => {
    // const isAuthenticated = localStorage.getItem('isAuthenticated');
    // const username = import.meta.env.VITE_LOGIN_USER;
    // const password = import.meta.env.VITE_LOGIN_PASSWORD;

    // const loginEnabled = username != null && password != null;

    // if (loginEnabled && !isAuthenticated) {
    //   return <LoginPage />;
    // }

    return <Outlet />;
  },
  pendingComponent: () => null,
  validateSearch: globalSearchSchema,
});
