import { Icon } from '$/components/common/Icon';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { t } from '$/components/core/Localization/18n';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { rasteringToast } from '$/pages/EditorPage/stores/editorActionToasts';
import { WorkMode } from '$/services/usecases/editor/mapper/editorStatus';

export const isActionPolyOnlyApplicable = (workMode: WorkMode) => {
  const actions = editorActions.flatMap((group) => group.actions);
  const action = actions.find((action) => action.mode === workMode);
  if (!action) return true;
  return action.isPolyOnly === true;
};

export const editorActions: EditorActionGroup[] = [
  {
    id: 'group1',
    groupable: false,
    actions: [
      {
        key: 'selectPoly',
        mode: 'selectPoly',
        icon: <Icon icon='editor_pointer' w='18px' h='18px' />,
        select: true,
        disabledRoles: [],
        tooltipKey: 'editor.selectTool.tooltip',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.SelectPoly);
        },
      },
    ],
  },
  {
    id: 'group2',
    groupable: true,
    actions: [
      {
        key: 'addPoly',
        mode: 'addPoly',
        isPolyOnly: true,
        needComponent: true,
        icon: <Icon icon='pencil' w='18px' h='18px' />,
        select: true,
        tooltipKey: 'editor.polygon.tooltip',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.CreateEditPoly);
        },
      },
      {
        key: 'shape_circle',
        mode: 'addEllipse',
        isPolyOnly: true,
        needComponent: true,
        icon: <Icon icon='shape_circle' />,
        select: true,
        disabledRoles: ['free'],
        tooltipKey: 'editor.ellipse.tooltip',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.AddEllipse);
        },
      },
      {
        key: 'shape_rectangle',
        mode: 'addRect',
        isPolyOnly: true,
        needComponent: true,
        icon: <Icon icon='shape_rectangle' />,
        select: true,
        disabledRoles: ['free'],
        tooltipKey: 'editor.rectangle.tooltip',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.AddRect);
        },
      },
      // {
      //   key: 'add_photo',
      //   isPolyOnly: true,
      //   icon: <Icon icon='add_photo' />,
      //   select: true,
      //   disabledRoles: ['free'],
      // },
    ],
  },
  {
    id: 'group3',
    groupable: true,
    actions: [
      {
        key: 'brush',
        mode: 'brush',
        icon: <Icon icon='brush' />,
        needComponent: true,
        select: true,
        tooltipKey: 'editor.brush.tooltip',
        onClick: ({ selectAction }, { toast }) => {
          selectAction(ModuleSendSelectAction.UseBrush);
          rasteringToast(t('editor.brushTool'), selectAction, toast);
        },
      },
      {
        key: 'magicWand',
        mode: 'magicWand',
        icon: <Icon icon='magic_wand' />,
        needComponent: true,
        select: true,
        tooltipKey: 'editor.magicWand.tooltip',
        onClick: ({ selectAction }, { toast }) => {
          selectAction(ModuleSendSelectAction.MagicWand);
          rasteringToast(t('editor.magicWand.tool'), selectAction, toast);
        },
      },
      {
        key: 'eraser',
        mode: 'rubber',
        icon: <Icon icon='eraser' />,
        needComponent: true,
        select: true,
        tooltipKey: 'editor.rubber.tooltip',
        onClick: ({ selectAction }, { toast }) => {
          selectAction(ModuleSendSelectAction.UseRubber);
          rasteringToast(t('editor.rubber.tool'), selectAction, toast);
        },
      },
    ],
  },
  // {
  //   id: 'group4',
  //   groupable: true,
  //   actions: [
  //     {
  //       key: 'retouch',
  //       mode: ['retouchPaint', 'retouchPattern'],
  //       icon: <Icon icon='repair' boxSize='16px' />,
  //       needComponent: true,
  //       select: true,
  //       tooltipKey: 'editor.retouchColor.tooltip',
  //       onClick: ({ selectAction }, { toast }) => {
  //         selectAction(ModuleSendSelectAction.UseRetouchPaint);
  //         rasteringToast(t('editor.retouchColor.tool'), selectAction, toast);
  //       },
  //     },
  //     // {
  //     //   key: 'stencil',
  //     //   icon: <Icon icon='stencil' />,
  //     //   select: true,
  //     //   disabledRoles: ['free'],
  //     // },
  //   ],
  // },
  {
    id: 'group6',
    groupable: false,
    actions: [
      {
        key: 'undo',
        icon: <Icon icon='undo' />,
        select: false,
        tooltipKey: 'editor.undo.tooltip',
        isDisabled: (editorStatus) => {
          return !editorStatus.canUndo;
        },
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.Undo);
        },
      },
      {
        key: 'redo',
        icon: <Icon icon='redo' />,
        select: false,
        tooltipKey: 'editor.redo.tooltip',
        isDisabled: (editorStatus) => {
          return !editorStatus.canRedo;
        },
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.Redo);
        },
      },
    ],
  },
];
